<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-header data-background-color="blue" class="card-header">
          <div class="card-header-info">
            <h4 class="title">Список товаров</h4>
            <div class="search-wrap md-layout">
              <md-field
                class="table-input md-layout-item md-large-size-10"
                :md-clearable="true"
                @md-clear="clearHandler('id')"
              >
                <label>Номер товара</label>
                <md-input v-model="searchId" @keyup.enter="searchHandler" />
              </md-field>

              <md-field
                class="table-input md-layout-item md-large-size-20"
                :md-clearable="true"
                @md-clear="clearHandler('name')"
              >
                <label>Наименование</label>
                <md-input v-model="searchName" @keyup.enter="searchHandler" />
              </md-field>

              <div
                class="
                  treeselect-wrapper
                  table-input
                  md-layout-item md-large-size-20
                "
              >
                <Treeselect
                  v-model="searchCategory"
                  :options="categories"
                  :before-clear-all="
                    () => {
                      clearHandler('category');
                    }
                  "
                  value-format="object"
                  value-consists-of="LEAF_PRIORITY"
                  placeholder="Категория"
                  @select="
                    (node) => {
                      node && searchHandler(true, node.label);
                    }
                  "
                />
              </div>

              <md-field
                class="table-input md-layout-item md-large-size-20"
                :md-clearable="true"
                @md-clear="clearHandler('brand')"
              >
                <label>Бренд</label>
                <md-select v-model="searchBrand" @md-selected="searchHandler">
                  <md-option
                    v-for="item in brands"
                    :key="item.id"
                    :value="item.name"
                    placeholder="Бренд"
                  >
                    {{ item.name }}
                  </md-option>
                </md-select>
              </md-field>

              <md-button
                class="md-raised md-primary md-layout-item md-large-size-10"
                @click="searchHandler"
              >
                Поиск
              </md-button>
            </div>
          </div>
        </md-card-header>
        <md-card-content>
          <ProductsTable
            @sort="sortProducts"
            @prev="goToPage('prev')"
            @next="goToPage('next')"
          />
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import ProductsTable from "@/components/Tables/ProductsTable";

import { mapState, mapGetters, mapActions } from "vuex";

import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  components: {
    ProductsTable,
    Treeselect,
  },

  data() {
    return {
      searchId: null,
      searchName: null,
      searchCategory: null,
      searchBrand: null,
      sortBy: "id",
      sortOrder: "asc",
      currentPage: 1,
    };
  },

  computed: {
    ...mapState({
      brands: (state) => state.additionalData.brands,
    }),

    ...mapGetters({ categories: "additionalData/reworkedCategories" }),
  },

  async mounted() {
    this.$store.commit("SET_SHOW_LOADER", true);
    await this.searchHandler();
    await this.getAdditionalData();
    this.$store.commit("SET_SHOW_LOADER", false);
  },

  methods: {
    ...mapActions("additionalData", ["getAdditionalData"]),
    ...mapActions("products", ["searchProducts"]),

    clearHandler(fieldName) {
      switch (fieldName) {
        case "id":
          this.searchId = null;
          break;
        case "name":
          this.searchName = null;
          break;
        case "category":
          this.searchCategory = null;
          break;
        case "brand":
          this.searchBrand = null;
          break;
        default:
          break;
      }

      this.searchHandler();
    },

    goToPage(direction) {
      direction == "prev" ? this.currentPage-- : this.currentPage++;
      this.searchHandler(false);
    },

    async searchHandler(toFirstPage = true, category) {
      this.$store.commit("SET_SHOW_LOADER", true);

      if (toFirstPage) {
        this.currentPage = 1;
      }

      await this.searchProducts({
        id: this.searchId,
        name: this.searchName,
        category: category || this.searchCategory?.label,
        brand: this.searchBrand,
        page: this.currentPage,
        sortBy: this.sortBy,
        sortOrder: this.sortOrder,
      });

      this.$store.commit("SET_SHOW_LOADER", false);
    },

    sortProducts(params) {
      this.sortBy = params.sortBy;
      this.sortOrder = params.sortOrder;
      this.searchHandler(false);
    },
  },
};
</script>

<style lang="scss" scoped>
.md-layout {
  max-width: 100%;
}

.search-wrap {
  display: flex;
  justify-content: space-between;

  & > *:not(:last-child) {
    margin-right: 8px;
  }

  & > *:not(:first-child) {
    margin-left: 8px;
  }

  .md-button {
    width: 20%;
    height: max-content;
    flex-shrink: 0;
    align-self: center;
  }

  .md-field {
    .md-input,
    label {
      color: white !important;
      -webkit-text-fill-color: white !important;
    }

    &::v-deep {
      .md-input {
        color: white !important;
        -webkit-text-fill-color: white !important;
      }

      .md-clear {
        background: transparent !important;
        box-shadow: none;
        top: 18px;

        i > svg {
          fill: white !important;
        }
      }
    }
  }

  .treeselect-wrapper {
    margin: 4px 8px 24px;
    display: flex;
    align-items: flex-end;
    border-bottom: 1px solid white;
    padding: 0;

    &::v-deep {
      .vue-treeselect {
        margin: 0 -10px;

        &__control {
          background: transparent;
          border: none;
        }

        &__placeholder,
        &__single-value {
          color: white;
        }

        &__label {
          color: black;
        }
      }
    }
  }
}
</style>
